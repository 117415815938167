<template>
  <div class="page-filter page-filter_border">
    <span class="page-filter__title" data-visible="button" @click="toggleFiltersDisplay()">
      <span class="page-filter__text"
        >{{ title }}&nbsp;<span class="page-filter-brand-category_count">({{ count }})</span></span
      >
      <span class="page-filter__ico" :class="{ active: !hideFilters }">
        <svg width="12" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.59 7.42L6 1.83 1.41 7.42 0 6l6-6 6 6-1.41 1.42z" fill="#010101" />
        </svg>
      </span>
    </span>
    <ul class="page-filter-list" :class="{ 'page-filter-list_hidden': hideFilters }">
      <li v-for="(category, key) in childList" :key="`page-filter-list__item-${key}`" class="page-filter-list__item">
        <uc-link
          :to="localePath({ name: 'product-catalog-page', params: buildParams(slug, getCategory(category), filters) })"
          class="page-filter-list__link page-filter-list_brand"
        >
          {{ category.title }}&nbsp;<span class="page-filter-brand-item_count">({{ category.count }})</span>
        </uc-link>
      </li>
    </ul>
  </div>
</template>

<script>
import NavigationRouteMixin from '~/src/components/navigation/mixin/NavigationRoute.mixin.js';

export default {
  mixins: [NavigationRouteMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: null
    },
    slug: {
      type: String,
      default: ''
    },
    childList: {
      type: Array,
      default: () => []
    },
    brand: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hideFilters: true
    };
  },
  computed: {
    filters() {
      let filters = [];

      if (this.brand) {
        filters = [{ topic: 'brand', filters: [this.brand] }];
      }

      return filters;
    }
  },
  mounted() {
    this.hideFilters = this.childList.length !== 1;
  },
  methods: {
    toggleFiltersDisplay() {
      this.hideFilters = !this.hideFilters;
    },
    displayFilters() {
      this.hideFilters = false;
    },
    getCategory(childCategory) {
      let category = {};
      if (childCategory && childCategory.slug) {
        category = { url: { category: [childCategory.slug] } };
      }

      return category;
    }
  }
};
</script>
