<template>
  <aside class="page-catalog-aside sticky-container">
    <div class="sticky-wrapper">
      <div class="sticky-bottom">
        <brand-item
          v-for="(folderCategory, folderKey) in brandCategories"
          :key="`brand-item-${folderKey}`"
          :title="folderCategory.title"
          :count="folderCategory.count"
          :slug="folderCategory.slug"
          :child-list="folderCategory.childList"
          :brand="brand"
        />
        <div v-if="false">
          <span v-for="(folderCategory, folderKey) in brandCategories" :key="`folder-category-${folderKey}`">
            <uc-link :to="buildUrl(folderCategory.slug)">
              {{ folderCategory.title }}: <span>{{ folderCategory.count }}</span>
            </uc-link>
            <ul>
              <li v-for="(category, key) in folderCategory.childList" :key="`category-child-list-${key}`">
                <uc-link :to="buildUrl(folderCategory.slug, category.slug)">
                  {{ category.title }}: <span>{{ category.count }}</span>
                </uc-link>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import BrandCategoryListMixin from '~/pages/brand/mixin/BrandCategoryList.mixin.js';

export default {
  name: 'BrandCategoryListDesktop',
  mixins: [BrandCategoryListMixin]
};
</script>
