<template>
  <section v-if="showFilters" class="page-filter-mobile">
    <div v-click-outside="hideFilters" :class="{ 'filter-is-open': showFilters }" class="mobile-filter">
      <div class="mobile-filter-button-wrapper">
        <div class="filter-header">
          <span class="button-back button-back_pad button-back_vis filter-back-button" @click="hideFilters">
            <svg
              class="button-back__arrow button-back__arrow_marg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="9.5px"
              height="16.5px"
            >
              <path
                fill-rule="evenodd"
                stroke="rgb(91, 100, 111)"
                stroke-width="1px"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                fill="none"
                d="M7.426,14.562 L0.566,7.770 L7.270,0.571 "
              />
            </svg>
            <span class="button-back__text button-back__text_sm">Назад</span>
          </span>
          <span class="filter-title">Категории</span>
        </div>
      </div>

      <div class="filter-list-wrapper">
        <brand-item
          v-for="(folderCategory, folderKey) in brandCategories"
          :key="`filter-list-mobile-${folderKey}`"
          :title="folderCategory.title"
          :count="folderCategory.count"
          :slug="folderCategory.slug"
          :child-list="folderCategory.childList"
          :brand="brand"
        />
      </div>
    </div>
    <div :class="{ block: showFilters }" class="mobile-menu-overlay" />
  </section>
</template>

<script>
import vClickOutside from 'v-click-outside';
import BrandCategoryListMixin from '~/pages/brand/mixin/BrandCategoryList.mixin.js';

export default {
  name: 'BrandCategoryListMobile',
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [BrandCategoryListMixin],
  props: {
    showFilters: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    showFilters(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
    }
  },
  beforeDestroy() {
    this.$eventBus.$emit('general:body:hold-scroll', false);
  },
  methods: {
    hideFilters() {
      this.$emit('update:showFilters', false);
    }
  }
};
</script>
