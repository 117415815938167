import Loader from '~/src/components/page-blocks/Loader.vue';
import BrandItem from '~/pages/brand/components/BrandCategoryItem.vue';

export default {
  name: 'BrandCategoryListDesktop',
  components: { Loader, BrandItem },
  props: {
    brand: {
      type: String,
      default: null
    },
    brandAgg: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      brandCategories: []
    };
  },
  computed: {
    categoryConfigs() {
      return this.$store.state.catalog.categoryConfig;
    }
  },
  methods: {
    getBrandCategoryList() {
      const categoryAgg = this.brandAgg.find(({ name }) => name === 'category') || [];
      const folderCategoryAgg = this.brandAgg.find(({ name }) => name === 'folderCategory') || {};

      const categoryAggList = Object.fromEntries(
        ((categoryAgg && categoryAgg.buckets) || []).map(({ key, doc_count }) => [key, doc_count])
      );

      const foldersCategoryList = Object.fromEntries(
        ((folderCategoryAgg && folderCategoryAgg.buckets) || []).map(({ key, doc_count }) => [key, doc_count])
      );

      const brandCategoryList = [];
      this.categoryConfigs
        .filter(({ slug }) => Object.keys(foldersCategoryList).indexOf(slug) !== -1)
        .forEach(({ slug, title, childList }) => {
          childList = (childList || [])
            .filter(({ slug }) => Object.keys(categoryAggList).indexOf(slug) !== -1)
            .map(({ slug, title }) => ({ slug, title, count: categoryAggList[slug] }));

          brandCategoryList.push({ slug, title, childList, count: foldersCategoryList[slug] });
        });

      return brandCategoryList;
    }
  },
  created() {
    if (this.brandAgg && this.brandAgg.length) {
      this.brandCategories = this.getBrandCategoryList();
    }
  }
};
