<template>
  <section class="brand-wrap">
    <app-breadcrumbs :with-container="true" :breadcrumbs-links="breadcrumbsLinks" />
    <div class="container">
      <div class="row no-gutters">
        <div class="col">
          <h1 class="header-section header-section_catalog header-section_black header-section_black-brand">
            {{ brandData.h1 || brandData.title }}
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row no-gutters">
        <div class="col">
          <div class="catalog-main-wrap">
            <brand-category-list-desktop :brand="slug" :brand-agg="brandAgg" />
            <brand-category-list-mobile :brand="slug" :brand-agg="brandAgg" :show-filters.sync="showMobileFilters" />
            <div class="page-hold page-hold-brand">
              <div class="page-hold__row">
                <div class="brand-category-mobile-title" @click.stop="showMobileFilters = true">
                  {{ $t('Категории') }}
                </div>
                <div v-if="description" class="brand-wrapper">
                  <input id="brand-text-button" type="checkbox" />
                  <label for="brand-text-button"><div class="brand-wrapper-chevron" /></label>
                  <div class="brand-block">
                    <div v-if="brandData.logo" class="brand-block-img">
                      <uc-image :img="brandData.logo" folder="content-image" :width="`200`" :height="`125`" />
                    </div>
                    <uc-render-html root-element="section" class="brand-block-text" :html="description" />
                  </div>
                </div>
                <uc-render-html v-if="content" root-element="section" :html="content" class="width-100percent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BrandCategoryListDesktop from '~/pages/brand/components/BrandCategoryListDesktop';
import BrandCategoryListMobile from '~/pages/brand/components/BrandCategoryListMobile';
import ScrollTop from '~/src/mixins/ScrollToTop.mixin';
import ScreenWidth from '~/src/mixins/ScreenWidth';
import AppBreadcrumbs from '~/src/components/general/AppBreadcrumbs';

export default {
  name: 'BrandIndex',
  components: {
    AppBreadcrumbs,
    BrandCategoryListDesktop,
    BrandCategoryListMobile
  },
  mixins: [ScrollTop, ScreenWidth],
  asyncData({ route, $api, $metaService, $sentry, app, redirect }) {
    const slug = route.params.slug;

    return Promise.all([
      $api.BrandService.fetchBrandAgg(slug, { hasImage: 1, withPrice: 1 }),
      $api.BrandService.fetchBrandInfo(slug),
      $api.BrandService.fetchBrandPageBlock(`${slug}_desc`),
      $api.BrandService.fetchBrandPageBlock(slug)
    ])
      .then((data) => {
        const [brandAggData = [], brandInfo = {}, brandDescription = {}, brandContent = {}] = data;
        const brandAgg = brandAggData.data || [];
        const { meta, ...brandData } = brandInfo.data;
        const description = (brandDescription.data && brandDescription.data.contentFormatted) || '';
        const content = (brandContent.data && brandContent.data.contentFormatted) || '';

        return {
          slug,
          brandAgg,
          brandData,
          description,
          content,
          meta: $metaService.getMeta(
            `${process.env.PROJECT_BASE_URL}${route.path}`,
            meta?.title || brandData.title || ' ',
            meta?.description || ' ',
            meta?.image || process.env.PROJECT_LOGO_URL,
            meta?.keyword || ' '
          )
        };
      })
      .catch((e) => {
        const response = e.response;
        if ($sentry) {
          $sentry.captureException(e);
        }
        if (response && response.data.code && response.data.code === 404) {
          const url = app.localePath({ name: 'not-found-error-page' });
          redirect(301, url);
        }
      });
  },
  data() {
    return {
      scrollPosition: 0,
      slug: null,
      brandData: {
        slug: null,
        title: null,
        titleUkr: null,
        h1: null,
        h1Ukr: null,
        ordering: 0,
        logo: null,
        menuImage: null,
        inMenu: null,
        meta: {
          title: null,
          keyword: null,
          description: null,
          image: null
        }
      },
      meta: {},
      content: null,
      description: null,
      showMobileFilters: false
    };
  },
  head() {
    return this.meta;
  },
  computed: {
    breadcrumbsLinks() {
      return [
        {
          routeName: 'brand-list',
          title: this.$t('Бренды')
        },
        {
          routeName: 'brand-show',
          title: this.brandData.title
        }
      ];
    }
  },
  watch: {
    screenWidth() {
      const banner = document.querySelector('.banner');
      if (banner && this.isScreenSmallMobile) {
        banner.style.backgroundPosition = 'center';
      }
    }
  },
  beforeMount() {
    this.scrollToTopMain();
  },
  mounted() {
    this.$nextTick(function () {
      global.addEventListener('scroll', this.onScroll);
    });
  },
  beforeDestroy() {
    global.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll(event) {
      const currentScrollPosition = event ? event.srcElement.scrollingElement.scrollTop : 0;
      const banner = document.querySelector('.banner');
      const squareLeft = document.getElementById('squareLeft');
      const squareRight = document.getElementById('squareRight');
      const leftSmallStar = document.getElementById('leftSmallStar');
      const leftBigStar = document.getElementById('leftBigStar');
      const rightSmallStar = document.getElementById('rightSmallStar');
      const rightBigStar = document.getElementById('rightBigStar');
      const starMovementPeriod = 4;
      const movementPeriodMobile = 20;
      const movementPeriodTablet = 14;
      const movementPeriodDesktop = 12;
      const squareMovementPeriod =
        (this.isScreenSmallMobile && movementPeriodMobile) ||
        (this.isScreenMobile && movementPeriodTablet) ||
        movementPeriodDesktop;

      if (!squareLeft || !squareRight || !leftSmallStar || !leftBigStar || !rightSmallStar || !rightBigStar) {
        return null;
      }
      if (this.isScreenSmallMobile) {
        banner.style.backgroundPosition = 'center';
      } else {
        banner.style.backgroundPosition = `center top -${this.scrollPosition / movementPeriodDesktop}px`;
      }

      squareLeft.style.backgroundPosition = `center right -${this.scrollPosition / squareMovementPeriod}px`;
      squareRight.style.backgroundPosition = `center left -${this.scrollPosition / squareMovementPeriod}px`;
      leftSmallStar.style.transform = leftBigStar.style.transform = `
      rotate(-${this.scrollPosition / starMovementPeriod}deg)`;
      rightSmallStar.style.transform = rightBigStar.style.transform = `
      rotate(${this.scrollPosition / starMovementPeriod}deg)`;
      this.scrollPosition = currentScrollPosition;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/brand/_brand-collect.scss" />
